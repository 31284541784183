<template>
  <a-modal
    :visible="visible"
    :destroyOnClose="true"
    :title="
      formState.type === 'add'
        ? formState.parentId === '0'
          ? '新增关系组'
          : '新增子关系组'
        : formState.parentId === '0'
        ? '编辑关系组'
        : '编辑子关系组'
    "
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form
      ref="formRef"
      :model="formState"
      :rules="rules"
      layout="vertical"
      style="width: 100%"
    >
      <a-form-item
        :label="formState.parentId === '0' ? '关系组名称' : '子关系组名称'"
        name="groupName"
      >
        <a-input
          v-model:value="formState.groupName"
          :placeholder="
            formState.parentId === '0'
              ? '请输入关系组名称'
              : '请输入子关系组名称'
          "
        >
          <template #addonBefore>
            <component
              @click="visibleIcon = true"
              class="addonBefore"
              :is="icons[`${formState.icon}`]"
            />
          </template>
        </a-input>
      </a-form-item>
      <a-form-item label="描述" name="description">
        <a-input
          v-model:value="formState.description"
          placeholder="请输入描述"
        />
      </a-form-item>
      <a-form-item
        label="父级"
        name="parentName"
        v-if="
          formState.type === 'add' &&
          formState.show &&
          formState.parentId != '0' &&
          !!formState.parentId
        "
      >
        <a-input v-model:value="formState.parentName" :disabled="true" />
      </a-form-item>
    </a-form>
    <template v-slot:footer>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" @click="handleOk" :loading="loading"
        >确认</a-button
      >
    </template>
  </a-modal>

  <icon-modal
    v-model:visible="visibleIcon"
    :init-value="formState.icon"
    @fnOk="value => (formState.icon = value)"
  />
</template>

<script>
import {
  Tree,
  Dropdown,
  Menu,
  Form,
  message,
  Typography,
} from 'ant-design-vue';
import * as icons from '@ant-design/icons-vue';
import IconModal from '@/components/iconModal';
import { reactive, ref, toRefs, watch } from 'vue';
import menuApi from '@/api/menu';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  components: {
    ADirectoryTree: Tree.DirectoryTree,
    ATreeNode: Tree.TreeNode,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    AForm: Form,
    ATypographyParagraph: Typography.Paragraph,
    AFormItem: Form.Item,
    IconModal,
  },
  props: {
    visible: Boolean,
    init: Object,
    jump: Boolean,
  },

  setup(props, context) {
    const store = useStore();
    const router = useRouter();
    const formRef = ref(null);
    const state = reactive({
      visible: false,
      visibleIcon: false,
      loading: false,
      openType: '',
      rules: {
        groupName: [
          {
            required: true,
            // formState.parentId === '0' ? '请输入关系组名称' : '请输入子关系组名称',
            message: '请输入关系组名称',
            trigger: 'change',
          },
          {
            max: 16,
            message: '字符长度不能超过16',
            trigger: 'change',
          },
        ],
        description: [
          {
            // required: true,
            message: '请输入描述',
            trigger: 'change',
          },
          {
            max: 32,
            message: '字符长度不能超过32',
            trigger: 'change',
          },
        ],
      },
    });
    const formState = reactive({
      groupName: '',
      description: '',
      parentId: '0',
      icon: 'FolderOpenOutlined',
      type: 'add',
      parentName: '',
      groupMessage: {},
      id: '',
      show: true,
    });
    // 父级增加子级,parentId 就传父级id
    // 子级增加 , parentId 就传父级0
    // parentId: "0" 表示时父级， 传 parentId: ""
    watch(
      () => props.init,
      init => {
        console.log('init关系组编辑', init);
        // formState.parentId = init.id;
        formState.type = init.type;
        // 需要增加一层判断，当前是父级还是子级
        if (init.groupMessage?.parentId === '0') {
          // 父级新增和编辑
          if (init.type === 'add') {
            formState.groupName = '';
            formState.description = '';
            formState.parentName = init.groupMessage?.groupName;
            formState.show = init.show;
            formState.parentId = init.groupMessage?.id;
            formState.icon = 'FolderOpenOutlined'
          } else {
            formState.groupName = init.groupMessage?.groupName;
            formState.description = init.groupMessage?.description;
            formState.id = init.groupMessage?.id;
            formState.parentId = init.groupMessage?.parentId;
            formState.icon = init.groupMessage.icon
          }
        } else {
          // 子级编辑
          formState.groupName = init.groupMessage?.groupName;
          formState.description = init.groupMessage?.description;
          formState.id = init.groupMessage?.id;
          formState.parentId = init.groupMessage?.parentId;
          formState.icon = init.groupMessage.icon
        }
        state.rules.groupName[0].message =
          formState.parentId == '0' ? '请输入关系组名称' : '请输入子关系组名称';
      }
    );
    // console.log('formState', formState.show, formState.type);
    const getList = () => {
      menuApi
        .getList({
          userId: store.state.account.user.id,
        })
        .then(res => {
          if (res) {
            res = res.map(i => {
              const obj = {
                id: i.id,
                parentId: i.parentId,
                path: '/group/groupItem?id=' + i.id,
                name: 'groupItem',
                menuKey: 'groupItem',
                type: 'groupItem',
                component: 'group/groupItem',
                accessWay: 'builtin',
                description: i.description,
                meta: {
                  title: i.groupName,
                  icon: i.icon || 'FolderOpenOutlined',
                  breadcrumb: true,
                  size: i.size,
                },
                hidden: false,
                alwaysShow: false,
              };
              if (i.children?.length) {
                i.children = i.children.map(item => {
                  return {
                    id: item.id,
                    parentId: item.parentId,
                    path: '/group/groupItem?id=' + item.id,
                    name: 'groupItem',
                    menuKey: 'groupItem',
                    type: 'groupItem',
                    component: 'group/groupItem',
                    accessWay: 'builtin',
                    description: item.description,
                    meta: {
                      title: item.groupName,
                      icon: i.icon || 'FolderOpenOutlined',
                      breadcrumb: true,
                      size: item.size,
                    },
                    hidden: false,
                    alwaysShow: false,
                  };
                });
                return {
                  ...obj,
                  children: i.children,
                };
              } else {
                return obj;
              }
            });
            let target = [
              {
                path: '/group',
                name: 'group',
                menuKey: 'group',
                component: 'group/index.vue',
                accessWay: 'builtin',
                description: '关系组关系组',
                meta: {
                  title: '关系组',
                  breadcrumb: true,
                },
                hidden: false,
                alwaysShow: false,
                children: [],
              },
            ];
            console.log('target 222', target);
            target[0].children = res;
            store.commit('account/setCustomMenus', target);
          }
        });
    };

    const handleOk = () => {
      formRef.value.validate().then(() => {
        state.loading = true;
        const obj = JSON.parse(JSON.stringify(formState));
        let type = 'add';
        formState.type === 'edit' && (type = 'edit');
        if (formState.type === 'add') {
          delete obj.id;
        }
        menuApi[type]({
          userId: store.state.account.user.id,
          ...obj,
        })
          .then(res => {
            state.loading = false;
            if (res) {
              message.success(
                formState.type === 'add' ? '添加成功' : '编辑成功'
              );
              getList();

              context.emit('fnOk', false);
              if (formState.type === 'add' || props.jump) {
                formRef.value.resetFields();
                // 跳转到相应的关系组详情页面
                let path = '/group/groupItem?id=' + res.id;
                router.push(path);
              }
            }
          })
          .catch(() => {
            // message.error('添加失败');
          });
      });
    };
    const handleCancel = () => {
      context.emit('fnOk', false);
    };

    return {
      formState,
      ...toRefs(state),
      handleOk,
      icons,
      formRef,
      handleCancel,
      getList,
    };
  },
};
</script>

<style scoped lang="less">
.addonBefore {
  color: #ff8f1f;
}
</style>
